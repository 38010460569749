export enum QueryParameter {
    ClientId = 'c',
    FilterCategory = 'fc',
    FilterFormat = 'ff',
    FilterPlatform = 'fp',
    FilterTab = 'tb',
    FilterTags = 'ft',
    LocationId = 'l',
    Origin = 'o',
    PlannerId = 'p',
    ScheduleFlowId = 'sf',
    SortBy = 'sb',
    TemplateContentBuildId = 'tcb',
    TemplateId = 't',
    TemplateVersionId = 'tv',
}
