import { SettingsUnion } from '@deltasierra/react/components/email-document';
import { isValidLink } from '@deltasierra/utilities/string';
import { useMemo } from 'react';

type UseValidateEmailPublishDataReturns = {
    isDataInvalid: boolean;
};

export function useValidateEmailPublishData(
    focusSettings: SettingsUnion | undefined,
): UseValidateEmailPublishDataReturns {
    const isDataInvalid = useMemo(() => {
        if (
            focusSettings?.__typename === 'EmailTemplateDocumentButtonContent' ||
            focusSettings?.__typename === 'EmailTemplateDocumentImageContent'
        ) {
            return !isValidLink(focusSettings.link.value);
        } else if (focusSettings?.__typename === 'EmailTemplateDocumentSocialContent') {
            return !focusSettings.images.value.some(value => isValidLink(value.link));
        } else {
            return false;
        }
    }, [focusSettings]);

    return {
        isDataInvalid,
    };
}
